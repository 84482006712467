.background-overlay {
  background-color: #ffffff;
  z-index: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.sign-in-title {
  margin-bottom: 1rem;
  display:inline;
  position: absolute;
  left: 30px;
  bottom: 131.5%;
  width: 0;
  color: white;
  @include md{
    left: 90px;
    bottom: 180.5%;
  }
  @include lg{
    left: 90px;
    bottom: 190%.5;

  }
  @include xl {
    display: inline;
    position: static;
    color:black;
  }
}

.sign-in-login-text {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  text-align: left;
  padding-left: 30px;
  @include xl{
    padding-left: 0px;
  }
}

.sign-in-msg{
  margin-bottom: .8rem;
  padding-left: 30px;
  text-align: left;
  @include xl{
    padding-left: 0px;
  }
}

.sign-in-row {
  margin-right: 0;
  margin-left: 0;
  //When we want to see carousel on top
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  place-content: center;
  margin-top: 0px;
  @include lg {
    min-height: 612px;
  }
  @include xl {
    position: relative;
  }
}

.sign-in-carousel-col {
  z-index: 0;
  margin-top: 0;
  margin-top: -23%;
  display: inline;
  app-banner {
    position: absolute;
    z-index: 20;
    width: calc(100%);
    bottom: -12%;
  }
  @include sm {
    margin-top: -20%;
  }
  @include md {
    margin-top: -13%;
  }
  @include lg {
    margin-top: -15%;
  }
  @include xl {
    margin-top: 0rem;
    app-banner {
      position: absolute;
      z-index: 11;
      width: calc(100% - 50px);
      bottom: -17px;
      left: 25px;
    }
  }
  padding-right: 0;
  padding-left: 0;
  align-self: flex-end;
}

.sign-in-form-col {
  text-align: center;
  background-color: #ffffff;
  .style-rule {
    z-index: 1;
    background-color: white;
  }
  @include lg {
    height: 100%;
  }
  @include xl {
    text-align: left;
  }

}

ngb-carousel {
  img {
    height: 50vh;
    @include md {
      height: 60vh;
    }
    @include lg {
      min-height: 500px;
    }
    @include xl {
      min-height: 500px;;
    }

  }
  .carousel-caption {
    text-align: left;
    bottom: 50px;
    left: 30px;
    padding-bottom: 0px;
    p{
      margin-bottom: 0px;
      font-size: 14px;
      width: 100%;
    }
    @include md{
      padding-bottom: 55px;
      padding-left: 60px;
      p{
        margin-bottom: 0px;
        font-size: 14px;
        width: 189px;
      }
    }
    @include lg{
      padding-bottom: 20px;
      padding-left: 60px;
    }
  }
  .carousel-control-prev {
    display: none;
    z-index: 0;
  }
  .carousel-control-next{
    display: none;
    z-index: 0;
  }

  @include md{
  .carousel-control-prev {
    display: grid;
    z-index: 20;
    }
    .carousel-control-next{
    display: grid;
      z-index: 20;
    }
  }
  @include lg {
    .carousel-control-prev {
      display: none;
      z-index: 0;
    }
    .carousel-control-next{
      display: none;
      z-index: 0;
    }
  }
}

.carousel-inner {
  @include xl {
    & .carousel-caption {
      text-align: left;
      color: black;
      left: 95%;
      right: -40%;
      bottom: -4%;
      vertical-align: top;
      min-height: 126px;
      p {
        margin-bottom: .1rem;
        font-size: 14px;
          width: 100%;
      }
    }
  }

}

@include xl {
  .carousel-indicators{
    z-index: 20;
    right: -38%;
    left: 80%;
    transform: rotate(90deg);
    bottom: 2%;
    width: 15%;
    li{
      background-color: GetOtherColor('grey-dark');
      height: 0.33rem;
      width: 1.20rem;
    }

    li.active {
      background-color: GetPrimaryColor('blue-regular');
    }
  }
}

.login-nav-btns{
  min-width: 250px;
  margin-left: 64px;
  @include xl {
    margin-left:0px;
  }
}
